import React, {useState, useEffect} from "react";
import {Link, useLocation, useHistory} from "react-router-dom";
import { gsap } from "gsap";

const Navigation =()=> {

  const location = useLocation();
  const history = useHistory();

  //console.log(history);

  const [what, setWhat] = useState("none");
  const [maintain, setMaintain] = useState("none");
  const [contact, setContact] = useState("none");

  const routerButtonColor= ["#184059","#5d8aa6", "#bf7b3f"];

  const [ locationKeys, setLocationKeys ] = useState([])
 
  const [mm,setMm]=useState(0);


const routers = ["/what","/maintain","/contact"];
const stateRouterRef = [what,maintain,contact];
const imgSource=["/assets/images/nav-1.png", "/assets/images/nav-2.png","/assets/images/nav-3.png"];



useEffect(() => {

  return history.listen(location => {
    if (history.action === 'PUSH') {
      setLocationKeys([ location.key ])
    }

    if (history.action === 'POP') {
      if (locationKeys[1] === location.key) {
        setLocationKeys(([ _, ...keys ]) => keys)

       console.log("for");

      } else {
        setLocationKeys((keys) => [ location.key, ...keys ])

       console.log("back");

       let getchildConChild = document.querySelectorAll("#navigation > div > #children");


       getchildConChild.forEach((item) => {
        item.style.display="none";
  
      })


if(location.pathname === "/what"){ 

  document.querySelector(".navi" + 0).style.display="block";
  document.querySelector(".navi" + 0).style.backgroundColor="routerButtonColor[e.currentTarget.dataset.index]";
}

if(location.pathname === "/maintain"){ 

  document.querySelector(".navi" + 1).style.display="block";
  document.querySelector(".navi" + 1).style.backgroundColor="routerButtonColor[e.currentTarget.dataset.index]";
}

if(location.pathname === "/contact"){ 

  document.querySelector(".navi" + 2).style.display="block";
  document.querySelector(".navi" + 2).style.backgroundColor="routerButtonColor[e.currentTarget.dataset.index]";
}

      console.log(mm);

    

      }
    }
  })

}, [ locationKeys, ])


 useEffect(() => {

switch(location.pathname) {
  case routers[0]:
    return setWhat("block");
    case routers[1]:
      return setMaintain("block");  
    case routers[2]:
      return setContact("block");
  default:
    return 'none';
}


}, []);



 const navigator = (e) => {


  setMm(e.currentTarget.dataset.index);

  let getchildConChild = document.querySelectorAll("#navigation > div > #children");

    getchildConChild.forEach((item) => {
      item.style.display="none";

    })
   
document.querySelector(".navi" + e.currentTarget.dataset.index).style.display="block";
document.querySelector(".navi" + e.currentTarget.dataset.index).style.backgroundColor="routerButtonColor[e.currentTarget.dataset.index]";

}

const doScale=(e)=>{

  gsap.to(e.currentTarget, {
    duration:1,
    scaleX:1.1,
    scaleY:1.1,
    ease: "elastic.out",

  });

}


const doScaleBack=(e)=>{

  gsap.to(e.currentTarget, {
    duration:1,
    scaleX:1,
    scaleY:1,
    ease: "elastic.out",

  });

}



return(
<>
    <nav id="navigation">
<div style={{display:"none"}}>allo</div>
    {routers.map((mRouters, index) => (

  <div data-index={index} onClick={navigator} onMouseEnter={doScale} onMouseLeave={doScaleBack} key={index}>
    
  <Link to= {mRouters} >
  <img src={imgSource[index]} alt="img" / >
   </Link>
  <div id="children" className={"navi" + index} style={{display:stateRouterRef[index],backgroundColor:routerButtonColor[index]}}></div>
</div>

))}
<div></div>

   <div id="logo"><img src="/assets/images/logo-final.png" alt="logo" /></div>

    </nav>

  
</>

);

}

export default Navigation;