
import {Link} from "react-router-dom";
import { gsap } from "gsap";
import React, {useState, useEffect} from "react";
import styles from '../../frontend.module.css'

const Front = () => {


  const doScale=(e)=>{

    gsap.to(e.currentTarget, {
      duration:1,
      scaleX:1.01,
      scaleY:1.01,
      ease: "elastic.out",
  
    });
  
  }
  
  
  const doScaleBack=(e)=>{
  
    gsap.to(e.currentTarget, {
      duration:1,
      scaleX:1,
      scaleY:1,
      ease: "elastic.out",
  
    });
  
  }




return(
    <>
 
  <section id="mainFrontView">

<Link to="/what" >
    <div className="children"><img src="/assets/images/front-what-we-do.png" alt="img" onMouseEnter={doScale} onMouseLeave={doScaleBack} /></div>
</Link>

<Link to="/maintain" >
    <div className="children"><img src="/assets/images/front-maintainable.png" alt="img" onMouseEnter={doScale} onMouseLeave={doScaleBack}/></div>
</Link>

<Link to="/contact" >
<div className="children"><img src="/assets/images/front-get-in-touch.png" alt="img"onMouseEnter={doScale} onMouseLeave={doScaleBack}  /></div>
</Link>

</section>
<footer id="mainFrontFooter">
    <div></div><div id="bottom-front">And yet ... more to come</div>
</footer>

</>

);

}

export default Front;