const MobileView = () => {

    return(
    <>
        <div>MobileView</div>
        
    </>
    )
    
    
    
    }
    
    export default MobileView