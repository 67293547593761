
import React from "react";

import {
 Switch,
 Route
} from "react-router-dom";

import Front from './Front';
import Whatwedo from './Whatwedo'
import MaintainAble from './MaintainAble';
import Getintouch from './Getintouch';


const DesktopView = () => {

return(
<>

  <Switch>
    <Route exact path='/' component={Front} />
    <Route  path='/what' component={Whatwedo} />
    <Route  path='/maintain' component={MaintainAble} />
    <Route  path='/contact' component={Getintouch} />
  </Switch>

</>

)



}

export default DesktopView
