import React from 'react';

import {BrowserRouter as Router} from 'react-router-dom';

import './App.scss';
import Content from './components/Content';
import Navigation from './components/Content/Navigation';

function App() {
  return (
    <>
   
<div id="outer-main"></div>
      <div id="mainContainer" >
        <Router>
          
          <nav>
            <Navigation />
          </nav>

          <Content />
        </Router>
      </div>
     
    </>
   
  );
}

export default App;
